/* Standard Eric Meyer reset */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Get the variables in early so they're available */
/*
  The breakpoints for all resolutions represented as pairs with the breakpoint name and its associated pixel width.
  @media (--medium) { ...styles... }
*/
/* Above-targt breakpoints */
/* Below-target breakpoints */
/* Motion reduction breakpoint */
/* High contrast breakpoints */
/*
  Allows you to create your own selectors
  http://cssnext.io/features/#custom-selectors
  .MyModule:--enter {
    ...
  }
*/
/*
  The z-indexes for the project.
  z-index: var(--zindex--nav);
*/
/* Import the utility classes */
.sr-only, .global-header__search .nav-item__label {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.color--text {
    color: #18212A;
  }
.color--action {
    color: var(--color-action);
  }
.color--red-200 {
    color: #EFD9D6;
  }
.color--red-400 {
    color: #CF8C83;
  }
.color--red-600 {
    color: #AF4030;
  }
.color--red-800 {
    color: #69261D;
  }
.color--red-1000 {
    color: #35130E;
  }
.color--blue-200 {
    color: #D6E3F0;
  }
.color--blue-400 {
    color: #85ACD3;
  }
.color--blue-600 {
    color: #3374B6;
  }
.color--blue-800 {
    color: #1F466D;
  }
.color--blue-1000 {
    color: #0F2337;
  }
.color--skyblue-200 {
    color: #DBEEF8;
  }
.color--skyblue-400 {
    color: #94CBEB;
  }
.color--skyblue-600 {
    color: #4DA9DD;
  }
.color--skyblue-800 {
    color: #2E6685;
  }
.color--skyblue-1000 {
    color: #173344;
  }
.bg--text {
    background-color: #18212A;
  }
.bg--action {
    background-color: var(--color-action);
  }
.bg--red-200 {
    background-color: #EFD9D6;
  }
.bg--red-400 {
    background-color: #CF8C83;
  }
.bg--red-600 {
    background-color: #AF4030;
  }
.bg--red-800 {
    background-color: #69261D;
  }
.bg--red-1000 {
    background-color: #35130E;
  }
.bg--blue-200 {
    background-color: #D6E3F0;
  }
.bg--blue-400 {
    background-color: #85ACD3;
  }
.bg--blue-600 {
    background-color: #3374B6;
  }
.bg--blue-800 {
    background-color: #1F466D;
  }
.bg--blue-1000 {
    background-color: #0F2337;
  }
.bg--skyblue-200 {
    background-color: #DBEEF8;
  }
.bg--skyblue-400 {
    background-color: #94CBEB;
  }
.bg--skyblue-600 {
    background-color: #4DA9DD;
  }
.bg--skyblue-800 {
    background-color: #2E6685;
  }
.bg--skyblue-1000 {
    background-color: #173344;
  }
.bg--gray-100 {
    background-color: #F5F7FA;
  }
.bg--gray-200 {
    background-color: #ECF0F4;
  }
.bg--gray-300 {
    background-color: #E3E9EF;
  }
.bg--gray-400 {
    background-color: #D9E1EA;
  }
.bg--gray-500 {
    background-color: #C6D2DF;
  }
.bg--gray-600 {
    background-color: #B3C3D5;
  }
.bg--gray-700 {
    background-color: #4E5C6A;
  }
.bg--gray-800 {
    background-color: #333E4A;
  }
.bg--gray-900 {
    background-color: #26303A;
  }
.bg--gray-1000 {
    background-color: #18212A;
  }
.rhythm > * + *, .rhythm--default > * + * { margin-top: 1em; }
.rhythm--small > * + * { margin-top: 0.5em; }
.rhythm--large > * + * { margin-top: 2em; }
.rhythm--extra-large > * + * { margin-top: 4em; }
.rhythm--deep * + *, .rhythm--deep-default * + * { margin-top: 1em; }
.rhythm--deep-small * + * { margin-top: 0.5em; }
.rhythm--deep-large * + * { margin-top: 2em; }
.rhythm--deep-extra-large * + * { margin-top: 4em; }
.text--align-center {
    text-align: center;
  }
.text--align-left {
    text-align: left;
  }
.text--align-right {
    text-align: right;
  }
.text--bold {
    font-weight: 700;
  }
.text--italic {
    font-style: italic;
  }
.text--light {
    font-weight: var(--font-weight--light);
  }
.text--small {
    font-size: .875rem;
  }
/* Import root styles */
* {
  box-sizing: border-box;
}
html, body {
  box-sizing: inherit;
  color: #18212A;
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.4;
  scroll-behavior: smooth;
}
html.lock-body, body.lock-body {
    overflow: hidden;
  }
body {
  padding-top: 4.8889rem;
}
strong {
  font-weight: 700;
}
.ltr {
  direction: ltr !important;
}
[dir="rtl"] .ltr--block {
      display: block;
      text-align: right;
    }
#card-count {
  font-size: 1.111rem;
  margin: 2rem 0 -1rem;
}
::selection {
  background-color: #DBEEF8;
}
/* Import all component styles */
.emro {
  align-items: center;
  display: flex;
  min-height: 60vh;
  justify-content: center;
}
.aspect {
  position: relative;
}
.aspect::before {
    display: block;
    content: '';
  }
.aspect > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
.aspect--16by9::before {
      padding-top: 56.25%;
      content: '';
    }
.aspect--4by3::before {
      padding-top: 75%;
      content: '';
    }
.aspect--2by1::before {
      padding-top: 50%;
      content: '';
    }
.blockquote, blockquote {
  padding: 1.111rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
.blockquote .figcaption, blockquote .figcaption {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    margin-top: 1rem;
  }
.brand {
  display: inline-block;
}
.brand--bordered {
    border: 1px solid var(--color-gray-4);
  }
.button, .link--button, .link--category, .link--cta {
  background-color: #0F2337;
  border: 1px solid transparent;
  border-radius: .4444rem;
  color: #ffffff;
  cursor: pointer;
  direction: ltr;
  display: inline-block;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 1.1111rem 1.2rem;
  text-decoration: none;
  transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
  user-select: none;
  white-space: nowrap;
}
.button + .button, .link--button + .button, .link--category + .button, .link--cta + .button {
    margin-left: 1ch;
  }
.button:hover, .link--button:hover, .link--category:hover, .link--cta:hover {
    background-color: #4DA9DD;
    text-decoration: 0;
  }
.button:focus, .link--button:focus, .link--category:focus, .link--cta:focus {
    /* background-color: var(--color-skyblue-600); */
    outline: #B3C3D5 auto 3px;
  }
.button:active, .link--button:active, .link--category:active, .link--cta:active {
    background-color: #4DA9DD;
    outline: none;
  }
.button[disabled] {
    background-color: gray;
    cursor: default;
  }
.button--white {
    background: #ffffff;
    color: #18212A;
  }
.button--white:hover, .button--white:active {
      background-color: #4DA9DD;
      border-color: #4DA9DD;
      color: #18212A;
      outline: none;
    }
.card {
  background-color: #ffffff;
  border: 1px solid #C6D2DF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 21.9444rem;
  position: relative;
  text-align: left;
  overflow: hidden;
}
.card .image {
    margin-bottom: calc(1.111rem / 2);
    width: 100%;
  }
.card figcaption {
    padding: 0 1.111rem 1.111rem;
  }
.card__cta {
    margin-top: auto;
    padding: 1.111rem;
  }
.card__chips {
    margin-bottom: 1.111rem;
  }
.card__langs {
    left: 1.111rem;
    position: absolute;
    right: 1.111rem;
    top: 1.111rem;
  }
.card--resource {
    min-height: 23rem;
  }
.card--resource .heading {
      font-size: calc(1.25rem + 0.08000000000000007 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
    }
.card--resource .card__cta {
      padding-bottom: 0;
    }
.card--resource .link--cta {
      font-size: .7778rem;
    }
[dir="RTL"] .card {
    text-align: right;
  }
.card.filtered--hide {
    display: none;
  }
.card.filtered--show {
    display: flex;
  }
.card--press {
    max-width: 16.222rem;
    min-height: 26rem;
  }
.card--press::before {
      content: '';
      display: block;
      height: 8px;
      background-color: #3374B6;
    }
.card--press figure {
      padding: 1.25rem 0;
    }
.card--press .card__chips {
      font-size: .8333rem;
      font-weight: 600;
      text-transform: uppercase;
    }
.card--press .heading--h4 {
      font-size: 1.333rem;
      margin-top: 2.1667rem;
    }
.card--press .link--cta {
      font-size: .778rem;
    }
.card--press .richtext {
      font-size: 1.111rem;
      margin-top: .5rem;
    }
.chip {
  border-radius: 50px;
  color: #ffffff;
  display: inline-block;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 1ch 1ch 0;
  padding: .375rem .75rem;
  user-select: none;
}
.chip--location {
    background-color: transparent;
    border: 1px solid #c6d2df;
    color: #4e4e4e;
    font-weight: 500;
  }
.chip--language {
    background-color: #ffffff;
    border: 1px solid #c6d2df;
    color: #18212A;
    font-weight: 400;
  }
[dir="rtl"] .chip {
    font-size: 1rem;
    margin: 0 0 1ch 1ch;
  }
.component {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.component--no-padding {
    padding: 0
  }
.component--bottom-padding {
    padding-bottom: 3rem;
    padding-top: 0;
  }
.component--top-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }
.component--large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: calc(3rem * 1.5);
  }
.component--bottom-large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: 0;
  }
.component--top-large-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: calc(3rem * 2);
  }
.component--bottom-larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: 0;
  }
.component--top-larger-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--small-padding {
    padding-bottom: 1.111rem;
    padding-top: 1.111rem;
  }
.component--bottom-small-padding {
    padding-bottom: 1.111rem;
    padding-top: 0;
  }
.component--top-small-padding {
    padding-bottom: 0;
    padding-top: 1.111rem;
  }
.dl {
  display: flex;
  flex-wrap: wrap;
}
.dl .dt {
    flex: 1 0 33%;
    font-weight: 700;
  }
.dl .dt::after {
      content: ': ';
    }
.dl .dd {
    flex: 0 1 67%;
  }
.figure {
  /* Figure */
}
/* Standard Eric Meyer reset */
/* Get the variables in early so they're available */
/* Import the utility classes */
/* Import root styles */
/* Import all component styles */
.form__item + .form__item {
      margin-top: 1.111rem;
    }
.heading, .richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-range: 320px 1280px; /* viewport widths between which font-size is fluid */
  font-weight: 700;
  line-height: 120%;
}
.heading--h1, .richtext h1 {
    font-size: calc(1.875rem + 1.9 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--h2, .richtext h2 {
    font-size: calc(1.75rem + 1.3599999999999999 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--h3, .richtext h3, .marquee--default .heading {
    font-size: calc(1.375rem + 1.065 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--h4, .richtext h4 {
    font-size: calc(1.25rem + 0.52 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--h5, .richtext h5 {
    font-size: calc(1.125rem + 0.42500000000000004 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--h6, .richtext h6 {
    font-size: calc(1rem + 0.125 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.heading--bold {
    font-weight: 700;
  }
[dir="rtl"] .heading {
    text-align: right;
  }
.hr {
  border: none;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.hr--default {
    background: #B3C3D5;
  }
.hr--dashed {
    border-top: 2px dashed #CF8C83;
  }
.hr--green {
    background: 2px var(--color-green);
  }
.hr--red {
    background: 2px var(--color-red);
  }
.hr--turquoise {
    background: 2px var(--color-turquoise);
  }
.hr--violet {
    background: 2px var(--color-violet);
  }
.hr--yellow {
    background: 2px var(--color-yellow);
  }
.icon {
  display: inline-block;
  height: calc(6.2222rem - 2rem);
  max-height: 100%;
  max-width: 100%;
  width: calc(6.2222rem - 2rem);
}
.icon use {
    height: 100%;
    width: 100%;
  }
.icon--brand {
    height: calc(6.2222rem + 4rem);
    width: calc(6.2222rem + 4rem);
  }
.image, .richtext img {
  height:auto;
  max-width: 100%;
}
.image--rounded {
    border-radius: 100%;
  }
.image--background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
.image__lazy-box {
      bottom: 4px;
      left: 0;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
.image__lazy-box .image__low-res {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 100%;
      }
.image__lazy-panel {
      background-color: #B3C3D5;
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 3;
    }
.image__lazy-wrapper {
      display: inline-block;
      position: relative;
    }
.image__lazy-wrapper.pre-load .image__low-res {
          filter: blur(6px);
        }
.image__lazy-wrapper.pre-load .image__lazy-panel {
          opacity: .5;
        }
.image__lazy-wrapper.is-loaded .image__low-res {
          filter: none;
        }
.image__lazy-wrapper.is-loaded .image__lazy-box {
          opacity: 0;
          transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.image__lazy-wrapper.is-loaded .image.is-lazy {
          opacity: 1;
          transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.input {
  background-color: #ffffff;
  border: 1px solid #D9E1EA;
  border-radius: 4px;
  color: #18212A;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: .875rem;
  line-height: 1;
  padding: .5rem;
}
.input:focus {
    outline-color: #CF8C83;
  }
.input::placeholder {
    color: var(--color-gray-4);
    opacity: .8;
  }
.input[type="radio"] + .label::before {
        border-radius: calc(20px / 2);
        content: '';
      }
.input[type="file"] {
    border-color: transparent;
  }
.input[type="date"], .input[type="datetime-local"], .input[type="time"], .input.select {
    background-image: url('../images/icons/select.svg');
    background-position: top 50% right .5rem;
    background-repeat: no-repeat;
  }
/* removes the default calendar icon in webkit browsers */
.input[type="date"]::-webkit-calendar-picker-indicator, .input[type="datetime-local"]::-webkit-calendar-picker-indicator, .input[type="time"]::-webkit-calendar-picker-indicator, .input.select::-webkit-calendar-picker-indicator {
      background-image: none;
    }
.input[type="date"]:hover,.input[type="date"]:active,.input[type="date"]:focus, .input[type="datetime-local"]:hover, .input[type="datetime-local"]:active, .input[type="datetime-local"]:focus, .input[type="time"]:hover, .input[type="time"]:active, .input[type="time"]:focus {
      background-image: none !important;
    }
.input[type="checkbox"], .input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0, 0, 0, 0, 0, 0);
    -webkit-clip-path: polygon(0, 0, 0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
.input[type="checkbox"] + .label, .input[type="radio"] + .label {
      display: block;
      font-weight: 400;
      height: 20px;
      margin-left: calc(2 * 1.111rem);
      position: relative;
    }
.input[type="checkbox"] + .label::after, .input[type="radio"] + .label::after {
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
        border-color: #CF8C83;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 20px;
        left: -30px;
        position: absolute;
        top: 0px;
        transform: scale(0);
        transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
        width: calc(14px * .75);
      }
.input[type="checkbox"] + .label::before, .input[type="radio"] + .label::before {
        border: 1px solid #18212A;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        position: absolute;
        height: 20px;
        left: -38px;
        top: 5px;
        transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 20px;
      }
.input[type="checkbox"]:focus + .label::before, .input[type="checkbox"]:active + .label::before, .input[type="radio"]:focus + .label::before, .input[type="radio"]:active + .label::before {
        /* border-color: var(--controlHighlight); */
      }
.input[type="checkbox"]:checked + .label::after, .input[type="radio"]:checked + .label::after {
          border-bottom: 2px solid #18212A;
          border-right: 2px solid #18212A;
          transform: rotate(45deg);
        }
.label {
  color: #18212A;
  cursor: pointer;
  display: block;
  font-size: 1.1111rem;
  font-weight: 700;
}
.link, .richtext a {
  border-bottom: 1px solid;
  color: #18212A;
  display: inline-block;
  padding-bottom: .025em;
  text-decoration: none;
  transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
}
.link:hover,.link:active,.link:focus, .richtext a:hover, .richtext a:active, .richtext a:focus {
    color: #3374B6;
  }
.link--button, .link--category, .link--cta {

    border: 0;
    text-align: center;
  }
.link--button:hover,.link--button:active,.link--button:focus, .link--category:hover, .link--category:active, .link--category:focus, .link--cta:hover, .link--cta:active, .link--cta:focus {
      color: #ffffff;
    }
.link--button {
    color: #ffffff;
    padding: 1.1111rem 1.2rem;
  }
.link--button.button--white {
      color: #18212A;
    }
.link--button.button--white:hover,.link--button.button--white:active,.link--button.button--white:focus {
        background-color: #E3E9EF;
      }
.link--button:focus {
      background-color: #4DA9DD;
    }
.link + .link--button, .richtext a + .link--button {
    margin-left: 1ch;
  }
.link--category {
    align-items: center;
    background-color: #ffffff;
    /* background-image: url(../images/icons/icon-arrow-black.svg);
    background-position: center right 1.775rem;
    background-repeat: no-repeat; */
    color: #18212A;
    display: flex;
    font-size: 1.111rem;
    font-weight: 400;
    margin: .33338rem 0;
    padding: .8333rem 4.333rem .8333rem 1.25rem;
    text-align: left;
    width: 100%;
  }
.link--category img {
      margin-right: 1.25rem;
    }
.link--category:hover,.link--category:active,.link--category:focus {
      background-color: #ECF0F4;
      background-position: center right 1.5rem;
      color: #18212A;
    }
[dir="rtl"] .link--category {
      justify-content: space-between;
      padding-right: .8333rem;
    }
.link--text.link--cta {
    background-color: transparent;
    /* background-image: url(../images/icons/icon-arrow-black.svg); */
    background-position: center right 1.775rem;
    background-repeat: no-repeat;
    color: #18212A;
    padding: 1rem 0;
  }
.link--text.link--cta:hover,.link--text.link--cta:active,.link--text.link--cta:focus {
      color: #4DA9DD;
    }
[dir="RTL"] .link--text.link--cta {
      padding-right: 0;
    }
.link--back {
    border-color: transparent;
    cursor: pointer;
    font-size: .8333rem;
    font-weight: 600;
    text-transform: uppercase;
  }
.link--back:hover,.link--back:active,.link--back:focus {
      border-color: inherit;
    }
.link--back::before {
      content: '<';
      display: inline-block;
      padding-right: 1ch;
    }
.link--social {
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    display: inline-block;
    height: 2.222rem;
    text-decoration: none;
    width: 2.222rem;
  }
.link--social:hover,.link--social:active,.link--social:focus {
      opacity: .82;
    }
.link--social.social--facebook {
      background-image: url(../images/icons/btn-facebook.svg);
    }
.link--social.social--twitter {
      background-image: url(../images/icons/btn-twitter.svg);
    }
.link--social.social--mail {
      background-image: url(../images/icons/btn-mail.svg);
    }
.link--social + .link--social {
      margin-left: .722rem;
    }
.list, .richtext ol, .richtext ul {
  list-style: unset;
  padding-left: 2em;
}
.list .li, .richtext ol .li, .richtext ul .li {
    margin: 0 0 .5em 0;
  }
.list--blank {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline .li {
      display: inline-block;
      margin: 0 1em 0 0;
    }
.list--ordered, .richtext ol {
    list-style-type: decimal;
  }
.list--unordered, .richtext ul {
    list-style-type: disc;
  }
.media-object {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* alignment variants */
.media-object--top { align-items: flex-start; }
.media-object--middle { align-items: center; }
.media-object--bottom { align-items: flex-end; }
/* sizing variants */
.media-object--2-3 > :first-child {
      flex-basis: 35%;
    }
.media-object--2-3 > :last-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :first-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :last-child {
      flex-basis: 35%;
    }
.media-object--50-50 > :first-child {
      flex-basis: 50%;
    }
.media-object--50-50 > :last-child {
      flex-basis: 50%;
    }
.media-object__figure {
    margin-bottom: 2rem;
    order: -10;
  }
.media-object--no-stack .media-object__figure {
      margin-bottom: 0;
      margin-top: 2rem;
      order: 0;
    }
/* figure alignment variants */
.media-object__figure--top { align-self: flex-start; }
.media-object__figure--middle { align-self: center; }
.media-object__figure--bottom { align-self: flex-end; }
/* body alignment variants */
.media-object__body--top { align-self: flex-start; }
.media-object__body--middle { align-self: center; }
.media-object__body--bottom { align-self: flex-end; }
.paragraph, .richtext p {
  display: block;
}
.paragraph--taller {
    line-height: 1.6;
  }
.richtext strong {
    font-weight: 700;
  }
.richtext em {
    font-style: italic;
  }
.richtext li {
    margin: 0 0 .5rem 0;
  }
.richtext sub, .richtext sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
.richtext sup {
    /* Move the superscripted text up */
    top: -.5em;
  }
.richtext sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -.25em;
  }
.richtext table {
    display: block;
    width: 100%;
  }
.richtext table tbody, .richtext table tfoot, .richtext table tr {
      display: block;
    }
.richtext table tr:nth-child(even) {
        background-color: var(--color-gray-4);
      }
.richtext table th, .richtext table td {
      border-bottom: 1px dotted var(--color-gray);
      display: flex;
      justify-content: flex-end;
      padding: .5rem;
    }
.richtext table th {
      display: none;
      font-weight: 700;
      text-align: left;
    }
.richtext table td::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 700;
        text-align: left;
      }
.richtext table td > * {
        text-align: right;
      }
[dir="rtl"] .richtext {
    text-align: right;
  }
.select {
  appearance: none;
  color: #18212A;
  display: inline-block;
  font-size: .8333rem;
  font-weight: 700;
  padding-right: 2rem;
}
.svg {
  /* Svg */
}
.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}
.table {
  width: 100%;
}
/* default styles */
.table__caption {
    caption-side: bottom;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1.5rem 0;
  }
.table__cell {
    border-bottom: 1px dotted var(--color-gray);
    padding: .5rem;
  }
.table__cell--header {
      font-weight: 700;
      text-align: left;
    }
.table--mobile-scroll .table__cell {
      /* This rule exists to better demonstrate the responsive properties */
      white-space: nowrap;
    }
.table--mobile-collapse {
    display: block;
    width: 100%;
  }
.table--mobile-collapse .table__caption, .table--mobile-collapse .table__body, .table--mobile-collapse .table__foot, .table--mobile-collapse .table__row {
      display: block;
    }
.table--mobile-collapse .table__head {
      display: none;
    }
.table--mobile-collapse .table__row:nth-child(even) {
        background-color: var(--color-gray-4);
      }
.table--mobile-collapse .table__cell {
      display: flex;
      justify-content: flex-end;
    }
.table--mobile-collapse .table__cell::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 700;
        text-align: left;
      }
.textarea {
  min-height: 6rem;
  overflow: auto;
  resize: vertical;
  width: 100%;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 69.5554rem;
  padding: 0 .75rem;
  position: relative;
}
.wrapper--extra-large {
    max-width: 95.5555rem;
  }
.wrapper--large {
    max-width: 77.7778rem;
  }
.wrapper--medium {
    max-width: 69.5554rem;
  }
.wrapper--small {
    max-width: 59rem;
  }
.wrapper--featured {
    padding: 2rem 1rem;
  }
.accordion {
  border-bottom: 1px solid var(--color-gray-4);
  padding: 1rem 0;
  position: relative;
}
.accordion__head {
    font-size: calc(1.2rem + 0.244 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
    padding-bottom: 3rem;
    text-align: center;
  }
.accordion__head-text {
    flex: 1 1 auto;
  }
.accordion__head-text .heading {
      margin-bottom: 3rem;
      text-align: center;
      text-transform: uppercase;
    }
.accordion__toggleall {
    display: none;
  }
.accordion__toggleall-collapse {
      display: none;
    }
.accordion__toggleall.is-active .accordion__toggleall-expand {
        display: none;
      }
.accordion__toggleall.is-active .accordion__toggleall-collapse {
        display: inline;
      }
.accordion__image {
    max-width: 10.16rem;
  }
.accordion__drawer {
    border-radius: 2px;
    margin-bottom: 0;
  }
.accordion__drawer .expandable__drawer {
      padding: 1rem 0;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.accordion__heading {
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-size: calc(1.125rem + 0.42500000000000004 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 1.25rem 4rem 1.25rem 1.125rem;
    position: relative;
    white-space: normal;
    width: 100%;
  }
[dir="rtl"] .accordion__heading {
      justify-content: flex-end;
    }
.accordion__heading:hover,.accordion__heading:active,.accordion__heading:focus {
      background-color: #ffffff;
      outline: none;
    }
.accordion__heading::after, .accordion__heading::before {
      background-color: #ffffff;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: 1.33rem;
      top: 2rem;
      transform-origin: center;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 1.25rem;
      z-index: 1;
    }
[dir="rtl"] .accordion__heading::after, [dir="rtl"] .accordion__heading::before {
        left: 2.4rem;
        right: unset;
      }
.accordion__heading::after {
      transform: rotate(90deg);
      content: '';
    }
.accordion__heading span {
      background-color: #18212A;
      border-radius: 27px;
      display: block;
      height: 34px;
      position: absolute;
      right: 18px;
      top: 20px;
      width: 34px;
      z-index: 0;
      
    }
[dir="rtl"] .accordion__heading span {
        left: 1.75rem;
        right: unset;
      }
.accordion__heading[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
.accordion__heading[aria-expanded="true"]::after {
        transform: rotate(180deg);
        content: '';
      }
.accordion__heading[aria-expanded="true"]::before {
        transform: rotate(180deg);
        content: '';
      }
.card-deck__layout {
    display: flex;
    flex-wrap: wrap;
    gap: 1.111rem;
  }
.collection-links {
  padding: 0 1.111rem;
}
.collection-links__layout {
    align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
.collection-links .heading {
    margin-bottom: 3rem;
  }
.collection-links .richtext {
    font-size: 1.555rem;
    margin-bottom: 3rem;
  }
.plaque--50 .collection-links .richtext {
      font-size: 1.333rem;
    }
.collection-links__links {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
.plaque--50 .collection-links__links {
      width: 100%;
    }
.expandable {
  background-color: #ffffff;
  /*
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  */
  border-radius: 12px;
  overflow: hidden;
  transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
}
.expandable:not(.is-expanded) .expandable__drawer {
      /* box-shadow: inset 0 -2px 5px 0 var(--color-gray-4); */
    }
.expandable.is-expanded {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
.filters {
  border: 1px solid #C6D2DF;
  border-radius: 4px;
  margin-bottom: 1.111rem;
  position: relative;
}
.filters .expandable__toggle {
    cursor: pointer;
    display: block;
    font-size: 1.111rem;
    padding: 1.111rem 2rem 1.111rem 1.111rem;
  }
.filters .expandable__toggle::before {
      background-image: url(../images/icons/icon-dropdown.svg);
      background-position: top 7px right;
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 1rem;
      pointer-events: none;
      position: absolute;
      right: 1.111rem;
      top: calc(1.111rem + 3px);
      width: 1rem;
    }
.filters .expandable__drawer {
    padding: 0 1.111rem calc(1.111rem * 2) 1.111rem;
  }
[dir="rtl"] .filters .expandable__toggle {
      padding: 1.111rem 1.111rem 1.111rem 2rem;
    }
[dir="rtl"] .filters .expandable__toggle::before {
        right: unset;
        left: 1.111rem;
        content: '';
      }
.featured-resources {
  border-radius: 8px;
  margin: 3rem auto;
  max-width: 95.5555rem;
  padding: 3rem 0 1.5rem;
  text-align: center;
}
.featured-resources__layout {
    display: grid;
    grid-gap: 1.6667rem;
    margin: 3rem 0;
  }
.filterset {
  /* Filterset */
}
.global-footer {
  background-color: #F5F7FA;
  margin-top: 5rem;
  padding: 0 0 1.111rem;
  position: relative;
}
.global-footer::before {
    background-color: #ffffff;
    content: '';
    display: block;
    height: 11rem;
    left: 0;
    position: absolute;
    right: 0;
    top: -5rem;
  }
.global-footer--no-cta {
    padding-top: 1px;
  }
.global-footer--no-cta::before {
      display: none;
      content: '';
    }
.global-footer a {
    border-bottom: 1px solid transparent;
    color: #18212A;
    display: inline-block;
    text-decoration: none;
  }
.global-footer a:hover,.global-footer a:active,.global-footer a:focus {
      border-bottom: 1px solid;
    }
.global-footer__cta {
    background-color: #18212A;
    border-radius: 12px;
    color: #ffffff;
    padding: 5rem 0;
  }
.global-footer__cta .global-footer__cta-content {
      margin-top: 1rem;
    }
.global-footer__content {
    background-color: #ffffff;
    border-radius: 12px;
    margin-top: 4.333rem;
    padding: 4.333rem 0;
  }
.global-footer__content .heading {
      margin-bottom: 2rem;
    }
.global-footer__content .global-footer__content-layout {
      border-top: 1px solid #18212A;
      display: grid;
      grid-gap: 1.111rem;
      grid-template-columns: repeat(12, 1fr);
      padding-top: 3rem;
    }
.global-footer .global-footer__partners, .global-footer .global-footer__contact, .global-footer .global-footer__legal {
    grid-column: 1/span 12;
  }
.global-footer .global-footer__partners-icons {
    align-items: center;
    display: grid;
    justify-items: center;
    grid-gap: 80px 24px;
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
  }
.global-footer .global-footer__contact {
    font-size: 1.1111rem;
  }
.global-footer .global-contact-layout {
    display: grid;
    grid-gap: 1.111rem;
    grid-template-columns: repeat(12, 1fr);
  }
.global-footer .global-footer__contact-info {
    grid-column: 1/span 12;
  }
.global-footer .global-footer__contact-nav {
    grid-column: 1/span 12;
    margin: 3rem 0;
  }
.global-footer .global-footer__contact-nav a {
      margin-bottom: .75em;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    }
[dir="rtl"] .global-footer .global-footer__contact-nav a {
        font-size: 1.2em;
      }
.global-footer .global-footer__legal {
    border-top: 1px solid #18212A;
    font-size: .75rem;
    margin-top: 3rem;
    padding-top:3rem;
  }
.global-header {
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  height: 4.8889rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color .2s;
  z-index: 200;
}
.global-header__backdrop {
    background-color: transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 100vw;
    z-index: -1;
  }
.global-header__logo.logo {
    z-index: 201;
  }
.global-header__navigation {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
.global-header__navigation > .nav-utility {
      transform: translateX(-2.875rem);
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.global-header__navigation > .navigation > .nav-item {
        opacity: 0;
        transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
      }
.global-header__layout {
    align-items: center;
    display: flex;
    height: 4.8889rem;
    flex-direction: row;
    justify-content: space-between;
  }
.global-header__search .nav-item__link {
      border-bottom: 1px solid transparent;
      padding: .875rem 0;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.global-header__search:hover .nav-item__link,.global-header__search:active .nav-item__link,.global-header__search:focus .nav-item__link {
        border-bottom: 1px solid #18212A;
      }
.global-header__search .nav-item__link:hover,.global-header__search .nav-item__link:active,.global-header__search .nav-item__link:focus {
        border-bottom: 1px solid #18212A;
      }
.global-header__lang {
    max-width: 40vw;
  }
[dir="rtl"] .global-header {
    direction: ltr;
  }
.nav-item {
  background-color: transparent;
  border: none;
  display: inline-block;
  flex-grow: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
  width: 100%;
}
.nav-item[hidden] {
    display: none;
    pointer-events: none;
  }
.nav-item:focus-within .nav-item__label {
      border-bottom: 1px solid #18212A;
      color: #18212A !important;
    }
.nav-item.global-header__lang {
    border: solid 1px #D9E1EA;
    border-radius: 4px;
    margin: .5rem 0;
    padding-left: 0 !important;
    padding-right: 1rem;
  }
.nav-item.global-header__lang:focus-within {
      border: solid 1px #B3C3D5;
    }
.nav-item.global-header__lang .nav-item__toggle {
      background-image: url(../images/icons/icon-dropdown.svg);
      background-position: right .5rem center; 
      background-repeat: no-repeat;
      margin-left: .75rem;
      margin-right: 1.25rem;
    }
.nav-item.global-header__lang .nav-item__toggle .nav-item__label {
        border: 0 !important;
      }
[dir="rtl"] .nav-item.global-header__lang .nav-item__toggle {
        background-position: left .5rem center;
        margin-left: -.75rem;
        margin-right: .75rem;
        padding-left: 1.75rem;
      }
[dir="rtl"] .nav-item.global-header__lang {
      padding-left: 1rem !important;
      padding-right: 0 !important;
    }
.nav-item.global-header__search {
    padding-left: 0;
  }
.nav-item.global-header__search .nav-item__link {
      background-image: url(../images/icons/search.svg);
      background-position: right top 3px;
      background-repeat: no-repeat;
      border-bottom-color: transparent !important;
      margin-top: .5rem;
      padding: 1rem 1rem 1rem 0;
      width: 1.5rem;
    }
.nav-item__icon {
    max-height: 2.8125rem;
    max-width: 2.8125rem;
    height: 100%;
    padding: 1rem 0;
    width: 100%;
  }
.nav-item__menu {
    background-color: #ffffff;
    bottom: 0;
    display: none;
    height: auto;
    left: 100%;
    padding: .5rem;
    text-align: left;
    top: 0;
    overflow: auto;
    width: auto;
    z-index: 200;
  }
.nav-item__menu > .link {
      border-bottom: 1px solid transparent;
      color: #18212A;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: .8333rem;
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
      padding: .25rem .25rem;
      white-space: nowrap;
    }
.nav-item__menu > .link:hover,.nav-item__menu > .link:active,.nav-item__menu > .link:focus {
        border-bottom: 1px solid #18212A;
        color: #18212A;
        outline: none;
      }
.nav-item__label {
    cursor: pointer;
    display: inline-block;
    flex-direction: column;
    font-family: 'IBM Plex Sans';
    font-size: .8333rem;
    font-weight: 600;
    line-height: 1.0833;
    text-decoration: none;
    opacity: 1;
    outline: none;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    white-space: nowrap;
  }
[dir="rtl"] .nav-item__label {
      font-size: 1rem;
    }
.nav-item__link {
    color: #18212A;
    display: block;
    opacity: 1;
    outline: none;
    padding: .5rem 0;
    position: relative;
    text-align: left;
    text-decoration: none !important;
  }
[dir="rtl"] .nav-item__link {
      text-align: right;
    }
.nav-item__link::before {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.nav-item__link:focus .nav-item__label {
        color: #3374B6;
        text-decoration: none;
      }
.nav-item__link > .icon {
      height: 1.25rem;
      min-height: unset;
      min-width: unset;
      padding: 0;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 1.25rem;
    }
.nav-item__link > .icon[hidden] {
        display: none;
      }
.nav-item__toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    flex-direction: column;
    opacity: 1;
    outline: none;
    padding: 0;
    position: relative;
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.nav-item__toggle > .icon {
      height: 2.8125rem;
      min-height: unset;
      min-width: unset;
      padding: .7125rem .5rem;
      width: 2.8125rem;
    }
.nav-item__toggle > .icon[hidden] {
        display: none;
      }
.nav-item__toggle:focus .nav-item__label {
        color: #3374B6;
      }
.nav-item__toggle:focus > .icon::before {
          border: 3px solid #00b0ff;
          box-shadow: 0 0 4px 0 #00b0ff;
          content: '';
        }
.nav-item--icon .nav-item__toggle::before {
        bottom: -.5rem;
        content: '';
        left: -.5rem;
        position: absolute;
        right: -.5rem;
        top: -.5rem;
      }
.nav-item--icon .nav-item__toggle:focus::before {
          border: 3px solid #00b0ff;
          box-shadow: 0 0 4px 0 #00b0ff;
          content: '';
        }
.nav-item.is-open > .nav-item__menu {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }
.navigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}
.navigation__back {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    height: 2.8125rem;
    justify-content: space-between;
    opacity: 0;
    padding: .7125rem .5rem;
    position: absolute;
    right: calc(2.8125rem + 2rem);
    text-align: center;
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 2.8125rem;
    z-index: 200;
  }
.navigation__back[hidden] {
      pointer-events: none;
      opacity: 0;
    }
.navigation__back > .icon {
      fill: #69261D;
      min-height: unset;
      min-width: unset;
      stroke: transparent;
    }
.navigation__back::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.navigation__back:focus::after {
        border: 3px solid #00b0ff;
        box-shadow: 0 0 4px 0 #00b0ff;
        content: '';
      }
.navigation__drawer {
    background-color: #ffffff;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 3rem .75rem 0;
    pointer-events: none;
    position: fixed;
    opacity: 0;
    overflow-y: auto;
    top: 4.8889rem;
    /* transform: translateX(100%); */
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    transition-property: opacity;
    width: 100vw;
    z-index: 200;
  }
.navigation__drawer[hidden] {
      z-index: -1;
    }
.navigation__drawer .nav-item > .nav-item__toggle {
        padding: .5rem 0;
        width: 100%;
      }
.navigation__toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: column;
    height: 2.8125rem;
    justify-content: space-between;
    opacity: 1;
    outline: 0;
    padding: .7125rem .5rem;
    position: absolute;
    right: .35rem;
    text-align: center;
    transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 2.8125rem;
    z-index: 200;
  }
[dir="rtl"] .navigation__toggle {
      right: unset;
    }
.navigation__toggle > span {
      background-color: #18212A;
      height: 2px;
      display: block;
      left: 0;
      margin-top: 2px;
      opacity: 1;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 100%;
    }
.navigation__toggle > span:nth-child(1) {
        transform-origin: top left;
      }
.navigation__toggle > span:nth-child(3) {
        transform-origin: bottom left;
      }
.navigation__toggle::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.navigation.nav-open .navigation__drawer {
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    }
.navigation.nav-open .navigation__toggle > span:nth-child(1) {
        transform: translateY(-1px) translateX(5px) rotate(45deg);
      }
.navigation.nav-open .navigation__toggle > span:nth-child(2) {
        opacity: 0;
      }
.navigation.nav-open .navigation__toggle > span:nth-child(3) {
        transform: translateX(5px) rotate(-45deg);
      }
.navigation.nav-open + .nav-utility > .nav-item {
      opacity: 0;
    }
.navigation.sub-open .navigation__back {
      opacity: 1;
      pointer-events: initial;
    }
.navigation.sub-open .navigation__drawer {
      transform: translateX(-100%);
    }
.icon-card-deck__layout {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: 1.111rem;
    justify-content: space-around;
  }
.icon-card-deck__card {
    flex: 0 0 100%;
    padding: 1.111rem;
    text-align: center;

  }
.icon-card-deck__card img {
      height: 4.444rem;
    }
.icon-card-deck .richtext {
    font-size: 1.333rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 18rem;
  }
.input-radio {
  padding: calc(1.111rem / 2) 0;
}
.logo {
  display: inline-block;
  height: 2.666667rem;
  padding: 0;
  position: relative;
  width: 10.61111rem;
}
.logo__icon {
    display: block;
    height: 100%;
    width: 100%;
  }
.logo__icon::-moz-focus-inner {
      border: 0;
    }
.marquee {
  margin-top: 3rem;
  padding: 1.111rem 0;
  text-align: center;
}
.marquee__content {
    font-size: calc(1.125rem + 0.3193999999999999 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.marquee__heading, .marquee__content {
    margin: 0 auto 2rem;
    max-width: 47rem;
  }
.marquee--home {
    position: relative;
  }
.marquee--home .marquee__layout {
      align-items: center;
      background-color: #ffffff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      margin: 0 .5rem;
      padding: .75rem;
      position: relative;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.marquee--home .marquee__layout > i {
        display: none;
      }
.marquee--home .marquee__layout > i::before, .marquee--home .marquee__layout > i::after {
          background-color: #1F466D;
          border-radius: 6px;
          content: '';
          display: block;
          height: 12px;
          position: absolute;
          width: 12px;
        }
.marquee--home .marquee__layout > i:nth-child(1)::before {
            left: 26px;
            top: 26px;
            content: '';
          }
.marquee--home .marquee__layout > i:nth-child(1)::after {
            right: 26px;
            top: 26px;
            content: '';
          }
.marquee--home .marquee__layout > i:nth-child(2)::before {
            background-color: #4DA9DD;
            bottom: 26px;
            left: 26px;
            content: '';
          }
.marquee--home .marquee__layout > i:nth-child(2)::after {
            background-color: #4DA9DD;
            bottom: 26px;
            right: 26px;
            content: '';
          }
.marquee--home .marquee__bg {
      bottom: 0;
      display: grid;
      grid-gap: 1.777rem;
      grid-template-columns: 1fr;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
.marquee--home .marquee__bg i {
        border-radius: 8px;
      }
.marquee--home .marquee__bg i:nth-child(1) {
        background-color: #1F466D;
      }
.marquee--home .marquee__bg i:nth-child(2) {
        background-color: #AF4030;
      }
.marquee--home .marquee__bg i:nth-child(3) {
        background-color: #4DA9DD;
      }
.marquee--default .heading {
      margin-bottom: 2.33rem;
      text-transform: uppercase;
    }
.marquee--centered .heading, .marquee--centered .marquee__content {
      text-align: center;
    }
.press-list {
  /* PressList */
}
.resource {
  margin-top: 3rem;
  max-width: 58rem;
}
.resource__figure {
    margin: 1rem 0;
  }
.resource .richtext {
    font-size: calc(1rem + 0.33299999999999996 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.resource__cta, .resource__content, .resource__figure {
    margin-top: 3rem;
  }
.split-media__layout {
    align-items: center;
    display: grid;
    grid-gap: 1.111rem;
  }
.split-media__image {
    border-radius: 8px;
    max-width: 100%;
  }
.split-media__content {
    font-size: calc(1rem + 0.33299999999999996 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
  }
.split-media__cta {
    margin-top: 2.5rem;
  }
[dir="rtl"] .split-media {
    text-align: right;
  }
.split-media + .split-media {
    margin-top: calc(3rem * 1.5);
  }
.split-media__eyebrow {
    font-size: .8333rem;
    font-weight: 600;
    text-transform: uppercase; 
  }
.split-media--alt {
    color: #ffffff;
  }
.split-media--alt .split-media__image {
      border-radius: 0;
    }
.split-media--alt .split-media__heading {
      font-size: calc(1.375rem + 1.065 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
    }
.text-only__layout {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
.text-only__heading, .text-only__content {
    margin-bottom: 1.111rem;
  }
.text-only__content {
    font-size: calc(1rem + 0.33299999999999996 * ((100vw - 23.333333333333332rem) / 47.777777777777786));
    max-width: 34rem;
    text-align: center;
  }
.text-only--white {
    color: #ffffff;
  }
.text-only--justified .text-only__layout {
      align-items: flex-start;
    }
.text-only--justified .text-only__content {
      text-align: left;
    }
.video-embed {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.25rem;
}
.video-embed .button {
    font-family: var(--font-stack-heading);
    font-size: 1.25rem;
  }
.video-embed--narrow .video-embed__video {
      border: 1px solid #18212A;
      margin-left: auto;
      margin-right: auto;
      max-width: 46.875rem;
    }
.flex {
  flex-wrap: wrap;
  margin: 3rem 0;
}
/* until we can use Flex gap (https://caniuse.com/#search=gap) */
.flex > * {
    margin: calc(1.111rem / 2);
  }
.fullwidth-wrap {
  border-radius: 12px;
  margin: 1.111rem auto;
  max-width: 93.3333rem;
  padding: 3rem 0 1.5rem;
}
.grid {
  display: grid;
  grid-gap: 1.111rem;
  margin: 3rem 0;
}
.grid .grid {
    margin: 0;
  }
.pageshell {
  margin: 0 auto;
}
.plaque {
  background-color: #AF4030;
  border-radius: 12px;
  grid-column: 1/span 12;
  padding: 3.8889rem 0;
  position: relative;
  height: 100%;
}
.plaque--blue-600 {
    background-color: #3374B6;
  }
.plaque--blue-800 {
    background-color: #1F466D;
  }
.plaque--50 .collection-links .richtext {
      font-size: 1.333rem;
      max-width: 31rem;
      min-height: 5.6667rem;
    }
.plaque .richtext {
    max-width: 39rem;
  }
.plaque span {
    display: block;
    height: 36px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }
.plaque span::before, .plaque span::after {
      background-color: #ffffff;
      border-radius: 6px;
      content: '';
      display: block;
      height: 12px;
      position: absolute;
      width: 12px;
      z-index: 1;
    }
.plaque span::after {
      right: 1.111rem;
      content: '';
    }
.plaque span::before {
      left: 1.111rem;
      content: '';
    }
.plaque span:nth-child(1) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      top: 0;
    }
.plaque span:nth-child(1)::before, .plaque span:nth-child(1)::after {
        top: 1.111rem;
      }
.plaque span:nth-child(2) {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      bottom: 0;
    }
.plaque span:nth-child(2)::before, .plaque span:nth-child(2)::after {
        bottom: 1.111rem;
      }
.plaque__layout {
    display: grid;
    grid-gap: 1.111rem;
    grid-template-columns: repeat(12, 1fr);
  }
.about {
  /* About */
}
.campaign {
  /* Campaign */
}
.home {
  /* Home */
}
.resource-detail {
  /* ResourceDetail */
}
.sg-definition {
  border-bottom: 1px solid gray;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0;
  padding: 1.111rem 0;
}
.sg-definition__label {
    font-weight: bold;
  }
.sg-definition--body--grid > * {
        margin-top: 2rem;
      }
.sg-definition--heading {
    display: grid;
    grid-template-columns: 1fr;
  }
.sg-definition--heading .sg-h1-is-mobile {
      font-size: 1.875rem !important;
    }
.sg-definition--heading .sg-h2-is-mobile {
      font-size: 1.75rem !important;
    }
.sg-definition--heading .sg-h3-is-mobile {
      font-size: 1.375rem !important;
    }
.sg-definition--heading .sg-h4-is-mobile {
      font-size: 1.25rem !important;
    }
.sg-definition--heading .sg-h5-is-mobile {
      font-size: 1.125rem !important;
    }
.sg-definition--heading .sg-h6-is-mobile {
      font-size: 1rem !important;
    }
.sg-definition--palette {
    padding: 1.111rem;
  }
.sg-definition--palette .grid > * {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
.sg-definition--palette__card {
      border: 0;
      padding: 0;
    }
.sg-definition--palette__card .card {
        border: 0;
        border-bottom: 1px solid var(--color-gray-4);
        padding: 0 0 66.666% 0;
      }
.sg-definition--palette__card .dl {
        font-size: .7rem;
      }
.sg-definition--palette__card .figure .figcaption {
          padding: 1.111rem;
        }
.input[type="checkbox"]:focus + .label::before {
	content: '';
}
.input[type="checkbox"]:active + .label::before {
	content: '';
}
.input[type="radio"]:focus + .label::before {
	content: '';
}
.input[type="radio"]:active + .label::before {
	content: '';
}
.input[type="checkbox"]:checked + .label::after {
	content: '';
}
.input[type="radio"]:checked + .label::after {
	content: '';
}
[dir="rtl"] .accordion__heading::after {
	content: '';
}
[dir="rtl"] .accordion__heading::before {
	content: '';
}
.accordion__heading::before {
	content: '';
}
.plaque span:nth-child(1)::before {
	content: '';
}
.plaque span:nth-child(1)::after {
	content: '';
}
.plaque span:nth-child(2)::before {
	content: '';
}
.plaque span:nth-child(2)::after {
	content: '';
}
@media only screen and (min-width: 24rem) {
.global-footer .global-footer__partners-icons {
      grid-template-columns: 1fr 1fr
  }
    }
@media only screen and (min-width: 32rem) {
.marquee--home .marquee__layout {
        padding: 1.5rem
    }
.grid--library {
      grid-template-columns: repeat(2, 1fr)
  }
      }
@media only screen and (min-width: 48rem) {
body {
    padding-top: 6.2222rem
}
.media-object {
    flex-direction: row
}
.media-object > :first-child {
      margin-right: 2rem
  }
.media-object__figure {
      margin-bottom: 0;
      order: 0
  }
.media-object--no-stack .media-object__figure {
        margin-top: 0
    }
.richtext table {
      display: table
  }
.richtext table thead {
        display: table-header-group
    }
.richtext table tbody {
        display: table-row-group
    }
.richtext table tfoot {
        display: table-row-group
    }
.richtext table tr {
        display: table-row
    }
.richtext table th, .richtext table td {
        display: table-cell
    }
.richtext table td::before {
          content: ''
      }
.richtext table td > * {
          text-align: left
      }
.table__caption {
      caption-side: top
  }
.table--mobile-collapse {
      display: table
  }
.table--mobile-collapse .table__head {
        display: table-header-group
    }
.table--mobile-collapse .table__caption {
        display: table-caption
    }
.table--mobile-collapse .table__body {
        display: table-row-group
    }
.table--mobile-collapse .table__foot {
        display: table-row-group
    }
.table--mobile-collapse .table__row {
        display: table-row
    }
.table--mobile-collapse .table__cell {
        display: table-cell
    }
.table--mobile-collapse .table__cell::before {
          content: ''
      }
.wrapper {
    padding: 0 1.111rem
}
.wrapper--flush {
      padding: 0
  }
.wrapper--featured {
      padding: 2rem 0
  }
.accordion__head {
      display: flex;
      flex-direction: column;
      text-align: left
  }
.accordion__detail {
      align-items: flex-start;
      display: flex;
      font-size: 1.333rem;
      gap: 3rem;
      padding: 1.111rem 3rem 1.111rem 0;
      text-align: left
  }
[dir="rtl"] .accordion__detail {
        text-align: right
    }
.featured-resources {
    padding: 5rem 0
}
.featured-resources__layout {
      grid-template-columns: repeat(2, 1fr);
      margin: 4rem 0 1.111rem
  }
.global-footer__cta .global-footer__cta-layout {
        align-items: center;
        display: grid;
        grid-gap: 1.111rem;
        grid-template-columns: repeat(12, 1fr)
    }
.global-footer__cta .global-footer__cta-heading {
        grid-column: 1/span 2
    }
.global-footer__cta .global-footer__cta-content {
        font-size: 1.3334rem;
        grid-column: 3/span 10;
        margin-top: 0;
        padding-left: 3rem
    }
.global-footer__content .heading {
        margin-bottom: 3rem
    }
.global-footer .global-footer__partners {
      grid-column: 1/span 5
  }
.global-footer .global-footer__partners-icons {
      margin-bottom: 0
  }
.global-footer .global-footer__contact {
      grid-column: 7/span 6
  }
.global-footer .global-footer__legal {
      font-size: 1rem
  }
.global-header {
    height: 6.2222rem
}
.global-header__navigation > .nav-utility {
        transform: translateX(0)
    }
.global-header__navigation > .navigation {
        margin-right: 0
    }
.global-header__navigation > .navigation > .nav-item {
          opacity: 1
      }
.global-header__layout {
      height: 6.2222rem
  }
      .global-header.nav-open .global-header__backdrop {
        pointer-events: initial;
        z-index: 198;
      }
.nav-item {
    width: auto
}
.nav-item__label {
      border-bottom: 1px solid transparent;
      color: #18212A;
      padding: .875rem 0
  }
        .nav-item.is-open .nav-item__toggle > .nav-item__label::after {
          content: '';
          position: absolute;
          bottom: -1.5rem;
          left: calc(50% - 5px);
          z-index: 201;
        }
.nav-item__link {
      padding: 0
  }
.nav-item.is-open > .nav-item__menu {
        align-items: flex-end
    }
.navigation__back {
      opacity: 0;
      pointer-events: none;
      transition: none
  }
.navigation__drawer {
      overflow-y: unset;
      padding: 3rem 1.5rem 0;
      top: 6.2222rem
  }
.navigation__drawer .nav-item > .nav-item__toggle {
          border: 0;
          padding: 0
      }
.navigation__toggle {
      right: .6rem
  }
.navigation.sub-open .navigation__back {
        opacity: 0;
        pointer-events: none
    }
.navigation.sub-open .navigation__drawer {
        transform: translateX(0)
    }
.icon-card-deck__layout {
      flex-wrap: nowrap
  }
.icon-card-deck__card {
      flex: 0 0 33%

  }
.logo {
    height: 4rem;
    width: 15.1111rem
}
.marquee--home {
      padding: 2rem;
      margin-bottom: 1.5rem;
      margin-top: 4.6111rem
  }
.marquee--home .marquee__layout {
        margin: 0;
        padding: 4.2778rem 2rem
    }
.marquee--home .marquee__layout > i {
          display: inline-block
      }
.split-media__layout {
      grid-template-columns: repeat(12, 1fr)
  }
.split-media__image {
      grid-column: 1/span 6
  }
.split-media__content {
      grid-column: 8/span 5
  }
.split-media--reversed .split-media__image {
        grid-column: 7/span 6;
        grid-row: 1
    }
.split-media--reversed .split-media__content {
        grid-column: 1/span 5;
        grid-row: 1
    }
[dir="rtl"] .split-media .split-media__image {
        grid-column: 1/span 6
    }
[dir="rtl"] .split-media .split-media__content {
        grid-column: 8/span 4
    }
[dir="rtl"] .split-media--reversed .split-media__image {
          grid-column: 7/span 6;
          grid-row: 1
      }
[dir="rtl"] .split-media--reversed .split-media__content {
          grid-column: 1/span 5;
          grid-row: 1
      }
.split-media--alt .split-media__image {
        grid-column: 1/span 5
    }
.split-media--alt .split-media__content {
        grid-column: 7/span 6
    }
.split-media.split-media--reversed.split-media--alt .split-media__image {
        grid-column: 8/span 5
    }
.split-media.split-media--reversed.split-media--alt .split-media__content {
        grid-column: 1/span 6
    }
.flex {
    display: flex;
    gap: 1.111rem
}
.flex--no-wrap {
      flex-wrap: nowrap
  }
.flex--justify-center {
      justify-content: center
  }
.flex--space-between {
      justify-content: space-between
  }
.flex--space-evenly {
      justify-content: space-evenly
  }
.fullwidth-wrap {
    padding: 5rem 1.111rem
}
.grid--2col {
      grid-template-columns: 1fr 1fr
  }
.grid--3col {
      grid-template-columns: 1fr 1fr 1fr
  }
.grid--4col {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.grid--5col {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
  }
.grid--major-minor {
      grid-template-columns: 1fr 250px
  }
.plaque {
    padding: 5.8889rem 0
}
.plaque--50 {
      padding: 3.8889rem 0;
      grid-column: 1/span 6
  }
.plaque--50 + .plaque--50 {
        grid-column: 7/span 6
    }
.sg-definition {
    margin: 0;
    padding: 3rem 0
}
.sg-definition--body--grid > * {
          margin-top: 0
      }
.sg-definition--heading {
      grid-template-columns: 1fr 3fr 3fr
  }

      .sg-definition--heading-dynamic {
        grid-template-columns: 1fr 4fr 2fr;
      }
  }
@media only screen and (min-width: 64rem) {
.link--category {
      width: 21.1111rem
  }
.accordion__heading {
      padding: 2.33rem 4rem 2.33rem 4.125rem
  }
.accordion__heading::after, .accordion__heading::before {
        right: 3.25rem;
        top: 3.125rem;
        width: 1.66rem
    }
.accordion__heading span {
        height: 54px;
        right: 47px;
        top: 30px;
        width: 54px
      
    }
.featured-resources__layout {
      grid-template-columns: repeat(3, 1fr)
  }
.global-footer .global-footer__partners {
      grid-column: 1/span 4
  }
.global-footer .global-footer__contact {
      grid-column: 6/span 7
  }
.global-footer .global-footer__contact-info {
      grid-column: 1/span 7
  }
.global-footer .global-footer__contact-nav {
      grid-column: 8/span 5;
      margin: 0
  }
.global-header__search .nav-item__link {
        padding: 7px 0
    }
.global-header__lang {
      position: relative
  }
.nav-item {
    padding: 0 1.333rem;
    text-align: center
}
.nav-item.global-header__search .nav-item__link {
        margin-top: 0;
        width: 3rem
    }
.nav-item__menu {
      border: 1px solid #B3C3D5;
      border-top-color: transparent;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0px 4px 14px rgb(0 0 0 / 3%);
      bottom: unset;
      height: auto;
      left: -6.85rem;
      overflow: visible;
      padding: 1rem;
      position: absolute;
      top: 2.25rem;
      width: 11rem
  }
[dir="rtl"] .nav-item__menu {
        left: -1px;
        top: 2.5rem
    }
.nav-item__menu > .link {
        padding: .5rem 0;
        text-align: right
    }
.nav-item:hover .nav-item__label,.nav-item:active .nav-item__label,.nav-item:focus .nav-item__label, .nav-item.is-open .nav-item__label, .nav-item.is-active .nav-item__label {
        border-bottom: 1px solid #18212A
    }
.nav-item.global-header__search {
      margin-top: 7px;
      padding-right: 0
  }
[dir="rtl"] .nav-item.global-header__search {
        padding-left: 0;
        padding-right: 1.33rem
    }
.navigation__drawer {
      align-items: center;
      background-color: transparent;
      border: none;
      flex-direction: row;
      height: 6.2222rem;
      opacity: 1;
      padding: 0;
      pointer-events: all;
      position: relative;
      top: unset;
      transform: none;
      transition: .4s cubic-bezier(.165, .84, .44, 1) 0s;
      width: auto
  }
.navigation__toggle {
      opacity: 0;
      pointer-events: none
  }
.marquee--home {
      margin-bottom: 3rem;
      padding: 4.2778rem
  }
.grid--filters {
      grid-template-columns: 1fr 3fr
  }
.grid--library {
      grid-template-columns: repeat(3, 1fr)
  }
    }
@media screen and (min-width: 1280px) {
	.card--resource .heading {
		font-size: 1.33rem;
	}
	.heading--h1, .richtext h1 {
		font-size: 3.775rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 3.11rem;
	}
	.heading--h3, .richtext h3, .marquee--default .heading {
		font-size: 2.44rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.77rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.55rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1.125rem;
	}
	.accordion__head {
		font-size: 1.444rem;
	}
	.accordion__heading {
		font-size: 1.55rem;
	}
	.marquee__content {
		font-size: 1.4444rem;
	}
	.resource .richtext {
		font-size: 1.333rem;
	}
	.split-media__content {
		font-size: 1.333rem;
	}
	.split-media--alt .split-media__heading {
		font-size: 2.44rem;
	}
	.text-only__content {
		font-size: 1.333rem;
	}
}
@media only screen and (max-width: 48rem) {
    .sg-definition > * + * {
      margin-top: 2rem;
    }
  }
@media screen and (max-width: 420px) {
	.card--resource .heading {
		font-size: 1.25rem;
	}
	.heading--h1, .richtext h1 {
		font-size: 1.875rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 1.75rem;
	}
	.heading--h3, .richtext h3, .marquee--default .heading {
		font-size: 1.375rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.25rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.125rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1rem;
	}
	.accordion__head {
		font-size: 1.2rem;
	}
	.accordion__heading {
		font-size: 1.125rem;
	}
	.marquee__content {
		font-size: 1.125rem;
	}
	.resource .richtext {
		font-size: 1rem;
	}
	.split-media__content {
		font-size: 1rem;
	}
	.split-media--alt .split-media__heading {
		font-size: 1.375rem;
	}
	.text-only__content {
		font-size: 1rem;
	}
}
